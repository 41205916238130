import { useEffect, useRef } from "react";
import nextCookie from "next-cookies";
import Router from "next/router";
import Head from "next/head";
import { toast } from "react-toastify";

import { userApi } from "../services/api";
import { login } from "../services/auth";
import { useUser } from "../components/UserContext";
import { useCart } from "../components/CartContext";

import Layout from "../components/Layout";
import Container from "../components/Container";
import Title from "../components/Title";
import CTA from "../components/CTA";
import Form from "../components/Form";
import Input from "../components/Form/Input";

import { FormSection } from "../styles/global";

const Login = ({ token, cart_token, store_token }) => {
  const formRef = useRef(null);

  const { setUser } = useUser();
  const { setCart } = useCart();

  useEffect(() => {
    if (token) Router.push("/");
  }, []);

  async function handleSubmit({ email, password }) {
    try {
      const user = await userApi.session(email, password, store_token);

      if (user.message) {
        toast.error(user.message);
      } else {

        login(user.authentication_token, setUser, setCart, cart_token);
      }
    } catch (err) {
      console.log("error");
    }
  }

  return (
    <Layout cart_token={cart_token}>
      <Head>
        <title key="title">Login | Goods Br</title>
        <meta key="og-title" property="og:title" content="Login | Goods Br" />
      </Head>
      <Container>
        <FormSection>
          <Title>Entrar</Title>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <label htmlFor="email">
              Email<abbr title="Requerido">*</abbr>
              <Input
                type="email"
                name="email"
                placeholder="Digite seu email"
                required
              />
            </label>
            <label htmlFor="password">
              Senha<abbr title="Requerido">*</abbr>
              <Input
                type="password"
                placeholder="Digite sua senha"
                name="password"
                required
              />
            </label>
            <CTA button type="submit">
              Entrar
            </CTA>
          </Form>
        </FormSection>
      </Container>
    </Layout>
  );
};

Login.getInitialProps = async ctx => {
  const { token, cart_token, store_token } = nextCookie(ctx);

  return { token, cart_token, store_token };
};

export default Login;
